.ql-editor {
  min-height: 100px;
}

.ant-table-thead .ant-table-cell {
  background-color: #408432 !important;
  color: white !important;
}

.ant-table-cell {
  vertical-align: middle;
}