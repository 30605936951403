.background-text {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  font-size: 56px;
  color: rgba(255, 255, 255, 0.1);
  font-weight: bold;
  z-index: 1;
  white-space: nowrap;
  pointer-events: none;
  text-align: start;
  gap: 0;
  line-height: 0.8em;
  padding: 16px;
  animation: slideIn 1.5s ease-out forwards;
}

@media screen and (max-width: 768px) {
  .background-text {
      font-size: 56px;
  }
  .background-text {
      bottom: 75%;
  }
}