.image {
    flex-shrink: 0;
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.text {
    transition: opacity 0.3s ease, transform 0.3s ease; /* Transizione per opacità e trasformazione */
    width: 100%; /* Assicurati che il testo occupi tutta la larghezza */
    color: #FED03D;
}

.fade-in {
    transition: opacity 1s ease-in; /* Tempo per apparire */
    opacity: 1; /* Visibile */
}

.concernings-text {
    color: '#FFF' !important;
}

.fade-out {
    transition: opacity 1s ease-in; /* Tempo per scomparire */
    opacity: 0; /* Invisibile */
}

.my-btn {
    background-color: transparent;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    fill: #ffffff;
    color: #ffffff;
    border-color: #fff;
    border-style: solid;
    border-width: 2px;
    border-radius: 0;
    width: auto;
    padding: 16px;
    cursor: pointer;
    text-decoration: none;
}

.my-btn:hover {
    border-color: #FED03D;
    color: #FED03D;
    transition: .1s ease-in;
}

@media screen and (min-width: 768px) {
    .description {
        max-width: 50%;
    }
}